import React from "react"
import Hs from "../images/h-sperator.svg"

const UserInteraction = ({ title, children, className }) => {

  return (
    <div
      className={"space-y-3 lg:my-8  bg-no-repeat text-primary container lg:min-h-[856px] " + className}
    >
      <div className="lg:mr-24 lg:w-[600px] py-6 lg:py-12 px-4 text-center space-y-6 ">

        <h1 className="text-[34px] font-bold text-blue">
          <img src={Hs} alt="decoration" className="inline ml-1.5" />{title}
        </h1>

        <div>
          {children}
        </div>

      </div>
    </div>
  )
}

export default UserInteraction
