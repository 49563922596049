import React from "react"
import { /* graphql, Link,*/  navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/Seo"
//import useViewportWidth from "../hooks/useViewportWidth"
//import LoginBlock from "../components/LoginBlock";
//import LoginThirdParty from "../components/LoginThirdParty";
import { string } from "../util/strings";
import UserInteraction from "../components/user-interaction";
//import ForgetPasswordBlock from "../components/forget-password";
import { useDispatch, useSelector } from "react-redux";
//import { Formik, Field, Form, ErrorMessage } from "formik";

import { ForgetPasswordForm, ResetPasswordForm } from "../components/forget-password"

const RecoverPasswordPage = ({ data, ...props }) => {


  const seo = {}

  return (
    <Layout>
      <Seo {...seo} />

      <div className=""></div>
      <section className=" mt-[90px] lg:mt-[126px] lg:pt-12 pt-4 pb-12 bg-gray-500">
        <UserInteraction
          title={string('forget.title')}
          className="lg:bg-[url(/backgrounds/pattern5.svg)] bg-white"
        >
          <div className=" text-center ">
            <Revocer />
          </div>
        </UserInteraction>
      </section>

    </Layout >
  )
}

export default RecoverPasswordPage

const Revocer = () => {

  const state = useSelector(state => state.recoverPassword)
  const dispatch = useDispatch()

  const phase = state.phase

  const init = (values) => {
    //console.log(values)
    dispatch({
      type: "recover/init",
      payload: { email: values.email }
    })
  }

  const setPass = (values) => {
    //console.log(values)
    dispatch({
      type: "recover/set",
      payload: {
        token: values.token,
        email: state.email,
        newPassword: values.password
      }
    })
  }


  const onReset = () => {
    dispatch({
      type: "recover/reset",
      payload: {}
    })
  }


  //console.log(state)

  return (
    <div className="">
      {phase === 'init' &&
        <div className="space-y-4">
          <ErrorMessages messages={state.init.messages} />
          <ForgetPasswordForm onSubmit={init} loading={state.init.loading}  /* {...state.init} */ />
        </div>
      }
      {phase === 'set' &&
        <div className="space-y-4">
          <div>
            {string('forget.description_init')}
          </div>
          <ErrorMessages messages={state.set.messages} />
          <ResetPasswordForm onSubmit={setPass} onReset={onReset} loading={state.set.loading} />
        </div>
      }
      {phase === 'success' &&
        <div>
          <PasswordResetSuccess />
        </div>
      }
    </div>
  )
}

const ErrorMessages = ({ messages }) => {

  return (
    <div className="text-red-600">
      {messages.map((e, i) => (
        <div key={i}>{e}</div>
      ))}
    </div>
  )
}

const PasswordResetSuccess = () => {
  const dispatch = useDispatch()

  const reset = () => {
    dispatch({
      type: 'recover/reset',
      payload: {}
    })

    navigate('/login')
  }

  return (
    <div className="space-y-8">

      <h4 className="text-xl text-blue">הצלחה !</h4>

      <button onClick={reset} className="border border-navy p-3 hover:bg-navy hover:text-white">להתחברות עם הסיסמה החדשה</button>
    </div>
  )
}


export const Head = () => <title>שיחזור סיסמה</title>
