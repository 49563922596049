import React from "react";
//import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
//import { Link } from "gatsby";
import { string } from "../util/strings";


// A custom validation function. This must return an object
// which keys are symmetrical to our values/initialValues
const validate = (values) => {
  const errors = {};

  const required = 'שדה נדרש'

  /* if (!values.password) {
    errors.password = required;
  } */

  if (!values.email) {
    errors.email = required;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "כתובת מייל אינה חוקית";
  }

  return errors;
};

export const ForgetPasswordForm = ({ onSubmit, message, loading = false }) => {

  //submit handler

  return (
    <div className="">

      <Formik
        initialValues={{
          email: "",
          //password: "",
        }}
        validate={validate}
        onSubmit={async (values) => {
          onSubmit(values)
        }}
      >
        {({ isValid/* , errors */ }) => (
          <Form className="space-y-4">

            <div className="space-y-4 max-w-[382px] mx-auto mb-6">
              <div className="">
                <label htmlFor="email" className="form-label hidden">
                  אימייל *
                </label>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  className="form-control border border-grey2 placeholder:text-grey2 py-4 px-3 w-full max-w-[382px]"
                  style={{ direction: "RTL" }}
                  placeholder="אימייל"
                />
                <ErrorMessage name="email" component="div" className="error text-danger text-red-800" />
              </div>

            </div>
            <button type="submit" className="text-lg p-3 w-100 bg-navy text-white font-bold hover:bg-blue  min-w-[260px] transition border" disabled={loading || !isValid}>
              <div className="flex flex-row justify-center items-top">
                <div>החלפת סיסמה {" "}</div>
                {loading && <LoadngIconSvg />}
              </div>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}


const validate2 = (values) => {
  const errors = {};

  const required = 'שדה נדרש'

  if (!values.token) {
    errors.token = required;
  }

  if (!values.password) {
    errors.password = required;
  }

  if (!values.password2) {
    errors.password2 = required;
  }

  if (values.password2 !== values.password) {
    errors.password2 = string('register.password.not match');
  }
  /*
    if (!values.email) {
      errors.email = required;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "כתובת מייל אינה חוקית";
    } */

  return errors;
};

export const ResetPasswordForm = ({ onSubmit, onReset, message, loading }) => {

  //submit handler

  return (
    <div className="">

      <Formik
        initialValues={{
          token: "",
          //email: "",
          password: "",
          password2: "",
        }}
        validate={validate2}
        onSubmit={async (values) => {
          onSubmit(values)
        }}
      >
        {({ isValid/* , errors */ }) => (
          <Form className="space-y-4">

            <div className="space-y-4 max-w-[382px] mx-auto mb-6">


              <div className="">
                <label htmlFor="email" className="form-label hidden">
                  טוקן
                </label>
                <Field
                  id="token"
                  name="token"
                  type="text"
                  className="form-control border border-grey2 placeholder:text-grey2 py-4 px-3 w-full max-w-[382px]"
                  style={{ direction: "RTL" }}
                  placeholder="קוד שהתקבל במייל"
                />
                <ErrorMessage name="token" component="div" className="error text-danger text-red-800" />
              </div>


              {/* <div className="">
                <label htmlFor="email" className="form-label hidden">
                  אימייל *
                </label>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  className="form-control border border-grey2 placeholder:text-grey2 py-4 px-3 w-full max-w-[382px]"
                  style={{ direction: "RTL" }}
                  placeholder="אימייל"
                />
                <ErrorMessage name="email" component="div" className="error text-danger text-red-800" />
              </div> */}


              <div className="mb-3 ">
                <label htmlFor="password" className="form-label hidden">
                  סיסמה
                </label>
                <Field
                  id="password"
                  name="password"
                  type="password"
                  className="form-control border border-grey2 placeholder:text-grey2 py-4 px-3  w-full max-w-[382px]"
                  placeholder="סיסמה"
                />
                <ErrorMessage name="password" component="div" className="error text-danger text-red-800" />
              </div>

              <div className="mb-3 ">
                <label htmlFor="password" className="form-label hidden">
                  סיסמה שוב
                </label>
                <Field
                  id="password2"
                  name="password2"
                  type="password"
                  className="form-control border border-grey2 placeholder:text-grey2 py-4 px-3  w-full max-w-[382px]"
                  placeholder="סיסמה שוב"
                />
                <ErrorMessage name="password2" component="div" className="error text-danger text-red-800" />
              </div>

            </div>

            <button type="submit" className="text-lg p-3 w-100 bg-navy text-white font-bold hover:bg-blue  min-w-[260px] transition " disabled={loading || !isValid}>
              <div className="flex flex-row justify-center items-top">
                <div>החלפת סיסמה {" "}</div>
                {loading && <LoadngIconSvg />}
              </div>
            </button>

            <button className="bg-gray-400 text-lg py-3 px-6 mr-8 font-bold hover:bg-navy hover:text-white transition" onClick={onReset}>חזרה</button>

          </Form>
        )}
      </Formik>
    </div>
  )
}

/*
export const PasswordResetSuccess = () => {

  return (
    <div>
      הצלחה
      <Link to={'/login'} className="text-blue">
        להתחברות
      </Link>

      <button>Reset</button>
    </div>
  )
} */


const LoadngIcon = () => {
  return (
    <div class="flex justify-center items-center">
      <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  )
}


const LoadngIconSvg = () => {
  return (
    <div className="">
      <svg class="animate-spin ml-3 mt-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  )
}